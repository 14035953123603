import { Controller as r } from "@hotwired/stimulus";
class a extends r {
  replace(e) {
    e.preventDefault(), e.stopPropagation();
    const [, , t] = e.detail;
    this.element.outerHTML = t.response;
  }
  append(e) {
    e.preventDefault(), e.stopPropagation();
    const [, , t] = e.detail;
    this.element.insertAdjacentHTML("afterend", t.response);
  }
  prepend(e) {
    e.preventDefault(), e.stopPropagation();
    const [, , t] = e.detail;
    this.element.insertAdjacentHTML("beforebegin", t.response);
  }
}
export {
  a as default
};
